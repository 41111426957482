import React, { MouseEvent } from "react"
import { Link, navigate } from "gatsby"
import "./style.scss"
import LAEJS from "@services/LAEJS"

const isClient = typeof window !== "undefined"

interface IBackButton {
  data?: string
  backLinkNative?: boolean
}

const BackButton = ({ data, backLinkNative }: IBackButton) => {
  const onBackClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    LAEJS.jsOnBackPressed({
      done: () => {
        setTimeout(() => {
          if (isClient && typeof data === "string") {
            window.location.href = data
          }
        }, 100)
      },
    })
  }

  const onHistoryBack = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (isClient) {
      navigate(-1)
    }
  }

  return (
    <div className="back-button">
      {LAEJS.isWebView && backLinkNative ? (
        <a href="#back" onClick={onBackClick}>
          <img alt="Back" src="/images/pledge/register/Path.svg" />
        </a>
      ) : typeof data === "string" ? (
        <Link to={data}>
          <img alt="Back" src="/images/pledge/register/Path.svg" />
        </Link>
      ) : (
        <a href="#back" onClick={onHistoryBack}>
          {" "}
          <img alt="Back" src="/images/pledge/register/Path.svg" />
        </a>
      )}
    </div>
  )
}

export default BackButton
