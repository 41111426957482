import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import { Link } from "gatsby"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import FormButton from "@components/_atoms/button_form_input_submit"
import RegSuccess from "@components/_molecules/splash_link_success"
import * as progress from "@services/progress"
import * as errors from "@services/errors"

import LAEJS from "@services/LAEJS"
import { EVENT_TOKEN_DW_APP_SUBMITTED } from "@data/tracking_adjust"

const debug = require("debug")("lae:" + __filename)

class ApplicationReview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonSubmitted: false,
      parentName: "",
      employment: "",
      parentAddress: {
        street: "",
        apt: "",
        city: "",
        state: "",
        zip: "",
        generalError: null
      },
      childName: "",
      childAddress: {
        street: "",
        apt: "",
        city: "",
        state: "",
        zip: ""
      },
      childEmail: "",
      checkValue: false,
      regSuccess: false
    }
  }

  componentDidMount = () => {
    let {
      parentsName,
      employmentStatus,
      parentAddress,
      childName,
      childAddress,
      childEmail
    } = localStorage
    if (
      parentsName &&
      employmentStatus &&
      parentAddress &&
      childName &&
      childAddress &&
      childEmail
    ) {
      let pa = JSON.parse(parentAddress)
      let ca = JSON.parse(childAddress)

      this.setState({
        parentName: parentsName,
        employment: employmentStatus.toLowerCase(),
        parentAddress: {
          street: pa.address1,
          apt: pa.address2,
          city: pa.city,
          state: pa.state,
          zip: pa.postalCode
        },
        childName: childName,
        childAddress: {
          street: ca.address1,
          apt: ca.address2,
          city: ca.city,
          state: ca.state,
          zip: ca.postalCode
        },
        childEmail: childEmail
      })
    }
  }

  handleSubmit = ev => {
    ev.preventDefault()
    this.setState({ buttonSubmitted: true })
  }

  checkStatus = () => {
    setTimeout(() => {
      let _this = this,
        accountStatusQuery = {
          query: `query {
          investmentAccounts {
            id
            status
          }
        }`,
          variables: {}
        }
      const options = {
        method: "POST",
        data: JSON.stringify(accountStatusQuery),
        headers: headers.getHeaders(),
        url: headers.api
      }
      axios(options)
        .then(res => {
          if (res.data.errors) {
            _this.errorHandler(res.data.errors[0].message)
          } else {
            let { status } = res.data.data.investmentAccounts[0]
            debug(status)
            if (status === "provisioning") {
              _this.checkStatus()
            } else if (status === "open") {
              LAEJS.jsTrackAdjustEvent({
                json: {
                  eventToken: EVENT_TOKEN_DW_APP_SUBMITTED
                }
              })
              _this.completeRegistration()
            } else {
              localStorage.setItem("dwStatus", "retry")
              localStorage.setItem(
                "advisorSysAccountId",
                res.data.data.investmentAccounts[0].id
              )
              _this.setState({
                generalError: "There was a problem processing your information",
                buttonSubmitted: false
              })
            }
          }
        })
        .catch(error => {
          localStorage.setItem("dwStatus", "retry")
          _this.errorHandler(error)
        })
    }, 2000)
  }

  completeRegistration = () => {
    this.setState({ regSuccess: true })
    window.scrollTo(0, 0)
    let $wrapper = document.querySelector("#gatsby-focus-wrapper")
    $wrapper.style.backgroundColor = "#00b983"
    $wrapper.style.overflow = "hidden"
    $wrapper.style.height = "100vh"
    progress.updateProgress()
    setTimeout(function() {
      window.location = `/pledge/register/${progress.getNext()}`
    }, 3000)
  }

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }

  render() {
    const { data } = this.props

    return (
      <div>
        {!this.state.regSuccess ? (
          <div className="application-review-section">
            <div className="app-review">
              <div className="links">
                <p>Parents Name</p>
                <Link to="/pledge/register/your-info/">
                  {this.state.parentName}
                </Link>
              </div>
              <div className="links">
                <p>Employment Status</p>
                <Link
                  to="/pledge/register/employment"
                  style={{ textTransform: `capitalize` }}
                >
                  {this.state.employment}
                </Link>
              </div>
              <div className="links address">
                <p>Parent Address</p>
                <Link className="address" to="/pledge/register/personal-info/">
                  {this.state.parentAddress.street}{" "}
                  {this.state.parentAddress.apt}
                  <br />
                  {this.state.parentAddress.city},{" "}
                  {this.state.parentAddress.state}{" "}
                  {this.state.parentAddress.zip}
                </Link>
              </div>
              <div className="links">
                <p>Child's Name</p>
                <Link to="/pledge/register/child-info/">
                  {this.state.childName}
                </Link>
              </div>
              <div className="links ">
                <p>Child's Address</p>
                <Link
                  className="address"
                  to="/pledge/register/confirm-child-info/"
                >
                  {this.state.childAddress.street} {this.state.childAddress.apt}
                  <br />
                  {this.state.childAddress.city},{" "}
                  {this.state.childAddress.state} {this.state.childAddress.zip}
                </Link>
              </div>
              <div className="links">
                <p>Email</p>
                <Link to="/pledge/register/confirm-child-info/">
                  {this.state.childEmail}
                </Link>
              </div>
            </div>
            <div className="confirm">
              <input
                type="checkbox"
                value={this.state.checkValue}
                style={{}}
                onClick={ev => {
                  debug(this.state.checkValue)
                  this.setState({ checkValue: !this.state.checkValue })
                }}
              />
              <p>
                I have read and accept Learn & Earns™ terms & conditions below.
              </p>
            </div>
            <div className="tos">
              <p>
                I have carefully read, understand, and agree to the terms and
                provisions of LearnAndEarn App's{" "}
                <a
                  href="https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/94325f84-c9ba-4552-a7a6-96c9e04998f9/article/33423361"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                and{" "}
                <a
                  href="https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/7a83c556-8146-463d-8bfc-3ee24684b691/article/33292337"
                  target="_blank"
                >
                  Terms of Service
                </a>
                .
              </p>
              <p>
                I have carefully read, understand, and agree to the terms and
                provisions of Ant Money Advisor’s{" "}
                <a
                  href="https://www.antmoneyadvisors.com/advisory-agreement/"
                  target="_blank"
                >
                  Advisory Agreement
                </a>
                , and{" "}
                <a
                  href="https://www.antmoneyadvisors.com/privacy-policy/"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                and acknowledge the receipt of Ant Money Advisor’s Form ADV Part
                2A{" "}
                <a
                  href="https://www.antmoneyadvisors.com/wrap-fee-brochure-adv-part-2a/"
                  target="_blank"
                >
                  Wrap Fee Brochure
                </a>
                , Part 2B{" "}
                <a
                  href="https://www.antmoneyadvisors.com/brochure-supplement-adv-part-2b/"
                  target="_blank"
                >
                  Supplemental Brochure
                </a>
                , and{" "}
                <a
                  href="https://www.antmoneyadvisors.com/form-crs-adv-part-3/"
                  target="_blank"
                >
                  Form CRS
                </a>
                .
              </p>
              <embed
                src="https://apps.drivewealth.com/disclosures"
                style={{ height: `130vh` }}
              ></embed>
            </div>
            <form onSubmit={this.handleSubmit}>
              <GeneralSubmitMessage error={this.state.generalError} />
              <FormButton
                text="Confirm"
                isDisabled={
                  this.state.buttonSubmitted || !this.state.checkValue
                }
              />
            </form>
          </div>
        ) : (
          <RegSuccess msg="Application Submitted!" />
        )}
      </div>
    )
  }
}

export default ApplicationReview
