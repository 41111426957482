import React from "react"
import "./style.scss"

const SuccessSplash = ({ msg }) => (
  <div className="splash-link-success">
    <div className="backing">
      <div className="circle-check">
        <img src="/images/pledge/check.svg" alt="✓" />
      </div>
      <p>{msg ? msg : "Successfully Linked"}</p>
    </div>
  </div>
)

export default SuccessSplash
