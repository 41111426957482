import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import ApplicationReview from "@components/_molecules/application_review_pledge"
import ProgressBar from "@components/_atoms/progress_bar_reg"

const InvestPersonalInfoPage = () => {
  const { reviewApp } = data
  return (
    <Layout pageClass="pledge" type="gray">
      <SEO title={reviewApp.title} />
      <ProgressBar />
      <BackButton data={reviewApp.backLink} />
      <HeaderAndText data={reviewApp.heading} />
      <ApplicationReview />
    </Layout>
  )
}

export default InvestPersonalInfoPage
